import androidx.compose.runtime.*
import kotlinx.browser.window
import kotlinx.coroutines.awaitAnimationFrame
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.Image
import kotlin.js.Date
import kotlin.random.Random
import kotlin.time.Duration.Companion.seconds

val photos = listOf(
    "Avocado Toast" to "avocado toast.jpg",
    "Breakfast Waffle" to "banana.jpg",
    "Vietnamese Chicken Wings" to "carrot.jpg",
    "Ocean Clam" to "clam.jpg",
    "Complicated Avocado" to "complicated.jpg",
    "Complicated Avocado" to "corn.jpg",
    "Beef" to "garlic.jpg",
    "Salmon Teriyaki" to "rice.jpg",
    "Salad" to "salad.jpg",
    "Prawns" to "shrimp.jpg",
    "Spaghetti" to "spaghetti.jpg",
    "Mango Salad" to "tomato.jpg",
    "Breakfast Waffle" to "waffle.jpg",
    "Prawns" to "wine.jpg",
    "Drinks" to "drinks.jpg",
)

fun randomPhoto() = photos.random()

fun randomPhotos(count: Int = 1): List<Pair<String, String>> {
    val photosRemaining = photos.toMutableList()
    val result = mutableListOf<Pair<String, String>>()
    repeat(count) {
        result += photosRemaining.removeAt(Random.nextInt(photosRemaining.lastIndex))
    }
    return result
}

@Composable
fun MainPhotos() {
    val scope = rememberCoroutineScope()
    var previousPhoto by remember { mutableStateOf<String?>(null) }
    var photo by remember { mutableStateOf(randomPhoto()) }
    var factor by remember { mutableStateOf(1f) }

    LaunchedEffect(Unit) {
        // Preload images
        photos.forEach {
            Image().apply {
                src = "/photos/${it.second}"
            }
        }
    }

    LaunchedEffect(Unit) {
        while (scope.isActive) {
            delay(5.seconds)
            previousPhoto = photo.second
            photo = randomPhoto()
        }
    }

    LaunchedEffect(previousPhoto) {
        if (previousPhoto == null) return@LaunchedEffect
        factor = 0f
        val start = Date().getTime()
        while (true) {
            window.awaitAnimationFrame()
            val now = Date().getTime()
            val f = (now - start).toFloat() / 1.seconds.inWholeMilliseconds.toFloat()
            if (factor >= 1f) {
                factor = 1f
                break
            } else {
                factor = f
            }
        }
        previousPhoto = null
    }

    if (previousPhoto != null) {
        Div({
            classes(styles.mainPhoto)
            style {
                backgroundImage("url('/photos/$previousPhoto')")
            }
        }) {}
    }

    Div({
        classes(styles.mainPhoto)
        style {
            backgroundImage("url('/photos/${photo.second}')")
            opacity(factor)
            property("z-index", "1")
        }
    }) {}

    Div({
        classes(styles.mainPhotoTitle)
        style {
            property("z-index", "2")
        }
    }) {
        Span {
            Text(photo.first)
        }
    }
}
