import androidx.compose.runtime.*
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

val storyText = """
                I've known about "Farm to table" through YouTube videos from 2009-2010, when I was a college student girl. I totally fell in love with those videos, where the chefs prepare food from the ingredients they get from the farm; they cook and talk to diners at the dining table also on the farm premises.
                Later in 2015, I went to the US to fulfill my dream of studying Culinary Arts because I was so passionate about food. After a period of exposure to American culture, I understood what "Farm to table" really is. What is it about connecting and supporting local farmers, and then bringing their produce to the table in the freshest way. And the health benefits when you eat healthy and fresh foods. Then there are environmental benefits when you take advantage of and use local products, reduce smoke and dust caused by long-distance transportation of goods... All together forming a balance.
                I returned to Vietnam in 2020, opened T.A Kitchen and Bar with the purpose of making "farm to table". I want to become a connecting bridge so that farmers can rest assured to grow quality agricultural products, good for users' health. I want to create healthy food, to help people improve the quality of their food. And I want everyone to be able to access clean vegetables, clean fruits, and eat clean... a bit greedy in this context.
                Did you know that eating clean is the key to a balanced and healthy life? That's why I believe "Farm to table" - From farm to table - is the best solution. And I think I can't finish the story alone. I really need help...
                You can become a customer, you can share the story of eating clean T.A Kitchen, or you can become a part of T.A Kitchen (we are currently in phase 2 of expansion, I am looking for potential investors)...To help T.A Kitchen maintain and develop its business, firmly continue to write the story of "from farm to table" of Vietnamese people. In fact, the market share for the clean food industry, especially the "clean" restaurant segment, is a huge piece of cake that few people have touched in Vietnam!
                I myself am not good at doing communication, nor good at thinking of tricks like other big restaurants. At our T.A Kitchen and Bar, there are only healthy dishes from clean and quality ingredients, and the dedicated service of the staff only!
                Thank you all for accompanying T.A Kitchen and Bar. I always feel grateful and appreciate all the help.
            """.trimIndent().trim().split("\n")

val menus = listOf(
    "Breakfast all day" to listOf("breakfast.png", "breakfast-2.png"),
    "Special all day" to listOf("specials.png"),
    "Salads" to listOf("salads.png"),
    "Healthy bowls" to listOf("healthy.png"),
    "Snacks and Soups" to listOf("sharing.png"),
    "Entree" to listOf("entree.png", "entree-2.png"),
    "Drinks" to listOf("drinks.png"),
    "Wine & alcohol" to listOf("wine.png", "wine-2.png")
)

@Composable
fun MainContent() {
    Div({
        style {
            fontSize(32.px)
        }
    }) {
        Text("T.A Kitchen and Bar")
    }
    Div({
        style {
            lineHeight("1.45")
            fontFamily("Josefin Sans", "sans-serif")
        }
    }) {
        Div({
            style {
                opacity(.667f)
                marginTop(0.5.cssRem)
            }
        }) {
            Text("Breakfast • Lunch • Dinner • Chill Bar")
        }
        Div({
            style {
                marginTop(2.cssRem)
                textDecoration("underline")
                fontWeight("bold")
                fontSize(80.percent)
            }
        }) {
            Text("Where we’re located")
        }
        Div({
            style {
                marginTop(1.cssRem)
                opacity(.5f)
            }
        }) {
            Text("96 Xuân Thuỷ (Thao Dien Square), Thảo Điền, Quận 2, Ho Chi Minh City, Vietnam")
        }
        Div({
            style {
                marginTop(1.cssRem)
            }
        }) {
            Div {
                Text("⏰ Hours: 7:00 am — 8:30 pm")
                Br()
                Text("☎\uFE0F Hotline: 0784 160 929")
            }
        }
    }
    val instagramLink = "https://www.instagram.com/t.akitchenandbar/"
    A(instagramLink, {
        style {
            display(DisplayStyle.Block)
            marginTop(2.cssRem)
            fontWeight("bold")
            textAlign("left")
            fontFamily("Josefin Sans", "sans-serif")
            textDecoration("none")
            property("color", "inherit")
        }
        target(ATarget.Blank)
    }) {
        Span {
            Text("Follow us on Instagram ")
        }
        Span({
            style {
                color(styles.colors.primary)
            }
        }) {
            Text("➜")
        }
    }
    val photos = remember {
        randomPhotos(3)
    }
    Div({
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.SpaceBetween)
            marginTop(1.cssRem)
        }
    }) {
        photos.forEach {
            Div({
                classes(styles.socialPhoto)
                style {
                    backgroundImage("url('/photos/${it.second}')")
                }
                onClick {
                    window.open(instagramLink, "_blank")
                }
            }) {}
        }
    }
    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            flexWrap(FlexWrap.Wrap)
            justifyContent(JustifyContent.SpaceBetween)
            marginTop(1.cssRem)
        }
    }) {
        Img("/menu/intro.png") {
            classes(styles.menuPhoto)
            onClick {
                window.open("/menu/intro.png", "_blank")
            }
        }
    }
    Div({
        style {
            marginTop(2.cssRem)
            fontWeight("bold")
            textAlign("left")
            fontFamily("Josefin Sans", "sans-serif")
            textDecoration("none")
            property("color", "inherit")
        }
    }) {
        Span {
            Text("We have")
        }
    }
    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            flexWrap(FlexWrap.Wrap)
            justifyContent(JustifyContent.SpaceBetween)
            marginTop(1.cssRem)
        }
    }) {
        menus.forEach { menu ->
            var isOpen by remember { mutableStateOf(false) }
            Button({
                classes(styles.menuButton)
                onClick {
                    isOpen = !isOpen
                }
            }) {
                Text(menu.first)
            }
            if (isOpen) {
                menu.second.forEach { page ->
                    Img("/menu/$page") {
                        classes(styles.menuPhoto)
                        onClick {
                            window.open("/menu/$page", "_blank")
                        }
                    }
                }
            }
        }
    }

    Div({
        style {
            marginTop(2.cssRem)
            fontWeight("bold")
            textAlign("left")
            fontFamily("Josefin Sans", "sans-serif")
            textDecoration("none")
            property("color", "inherit")
        }
    }) {
        Span {
            Text("Our story")
        }
    }
    Div({
        classes(styles.story)
        style {
            marginTop(1.cssRem)
            textAlign("left")
        }
    }) {
        Span {
            storyText.forEachIndexed { index, it ->
                Text(it)
                if (index != storyText.lastIndex) {
                    Br()
                    Br()
                }
            }
        }
    }
    Div({
        style {
            marginTop(2.cssRem)
            opacity(0.5f)
        }
    }) {
        Span {
            Text("Thank you!")
        }
    }
}
