import androidx.compose.runtime.*
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.renderComposable

fun main() {
    renderComposable(rootElementId = "root") {
        Body()
    }
}

@Composable
fun Body() {
    Style(styles)
    Div({
        classes(styles.mainLayout)
    }) {
        Div({
            classes(styles.mainPhotos)
        }) {
            MainPhotos()
        }
        Div({
            classes(styles.mainContent)
        }) {
            MainContent()
        }
    }
}
