import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.LineStyle.Companion.Solid

object styles : StyleSheet() {
    object colors {
        val background = Color("#fff9f2")
        val primary = Color("#df8b37")
        val primaryDark = Color("#40250a")
    }

    val mainLayout by style {
        boxSizing("border-box")
        minHeight(100.vh)
        display(DisplayStyle.Flex)

        media(mediaMaxWidth(1200.px)) {
            self style {
                flexDirection(FlexDirection.Column)
            }
        }
    }

    val mainPhotos by style {
        background("black")
        position(Position.Relative)

        media(mediaMinWidth(1201.px)) {
            self style {
                width(45f.percent)
                height(100.vh)
                top(0.percent)
                left(0.percent)
                bottom(0.percent)
                position(Position.Fixed)
            }
        }

        media(mediaMaxWidth(1200.px)) {
            self style {
                width(100.percent)
                height(45.vh)
            }
        }
    }

    val mainContent by style {
        padding(4.cssRem)
        property("z-index", "1")
        property("box-shadow", "0 0 100px #000000aa")
        textAlign("center")

        media(mediaMinWidth(1201.px)) {
            self style {
                flexGrow(1f)
                minHeight(100.vh)
                marginLeft(45f.percent)
            }
        }

        media(mediaMaxWidth(1200.px)) {
            self style {
                minHeight(55.vh)
                padding(2.cssRem)
            }
        }
    }

    val mainPhoto by style {
        width(100f.percent)
        height(100f.percent)
        backgroundSize("cover")
        backgroundPosition("center")
        position(Position.Absolute)
    }

    val socialPhoto by style {
        flex(1)
        property("aspect-ratio", "1")
        backgroundSize("cover")
        backgroundPosition("center")
        borderRadius(.5.cssRem)
        cursor("pointer")

        self + not(firstChild) style {
            marginLeft(1.cssRem)
        }
    }

    val menuButton by style {
        marginLeft(4.cssRem)
        marginRight(4.cssRem)
        borderRadius(2.cssRem)
        cursor("pointer")
        backgroundColor(colors.background)
        property("border", "none")
        padding(2.cssRem)
        fontSize(18.px)
        fontWeight("bold")
        color(colors.primaryDark)
        backgroundColor(Color("#ffffff"))
        property("box-shadow", "1px 1px 2px #00000044")

        self + not(firstChild) style {
            marginTop(1.cssRem)
        }
    }

    val menuPhoto by style {
        width(100.percent)
        borderRadius(.5.cssRem)
        cursor("pointer")

        self + not(firstChild) style {
            marginTop(1.cssRem)
        }
    }

    val mainPhotoTitle by style {
        position(Position.Absolute)
        left(4.cssRem)
        right(4.cssRem)
        bottom(4.cssRem)
        fontSize(32.px)
        padding(2.cssRem)
        textAlign("center")
        color(Color("#ffffff"))
        backgroundColor(Color("#000000aa"))
        borderRadius(2.px)
        fontWeight("bold")
        boxSizing("border-box")

        media(mediaMaxWidth(1200.px)) {
            self style {
                fontSize(24.px)
                left(2.cssRem)
                right(2.cssRem)
                bottom(2.cssRem)
            }
        }
    }

    val story by style {
        borderRadius(1.cssRem)
        padding(1.5.cssRem)
        backgroundColor(Color("white"))
        border(1.px, Solid, colors.primary)
    }
}
